<template>
  <d-side-bar
    :top="top"
    :model="display"
    :backdrop="booking !== null"
    :right-position="position === 'right'"
    :identifier="'bookingModal' + bookingId"
    :left="position === 'left' ? 0 : 'auto'"
    :right="position === 'right' ? 0 : 'auto'"
    :width="position === 'right' ? '880px' : '840px'"
    bg-dark="unset"
    @change="onChange"
    @hidden="beforeHide"
    @shown="$emit('shown')"
  >
    <div
      slot="header"
      class="width-auto"
    >
      <b-row class="container-fluid pt-2 w-100">
        <b-col
          class="divider"
          :cols="innerWidth ? '5' : '6'"
        >
          <b-col v-if="booking">
            <div class="booking-playground-name">
              {{ BookingPlayground }}
            </div>
            <div v-if="booking">
              {{ booking.activity.name }}
            </div>
          </b-col>
          <b-col
            v-else
            class="mt-2 d-flex align-content-center justify-content-center"
          >
            <b-spinner
              variant="blue-light-spinner"
              type="grow"
            >
            </b-spinner>
          </b-col>
        </b-col>
        <b-col
          class="text-center"
          :cols="innerWidth ? '' : '6'"
          :class="innerWidth ? '' : 'p-0'"
        >
          <div v-if="booking">
            <p v-if="innerWidth"
               class="booking-date-time mt-1"
            >
              {{ dateTime }}
            </p>
            <div
              v-else
              class="mt-0 ml-4"
            >
              <p class="booking-date-time m-0">
                {{ dateBooking }}
              </p>
              <p class="booking-date-time">
                {{ dateStartEnd }}
              </p>
            </div>
          </div>
          <p
            v-else
            class="mt-2 d-flex align-content-center justify-content-center"
          >
            <b-spinner
              variant="blue-light-spinner"
              type="grow"
            >
            </b-spinner>
          </p>
        </b-col>
      </b-row>
    </div>
    <template
      v-if="booking"
    >
      <div
        class="container-body-scroll"
        slot="body"
      >
        <div class="container-fluid">
          <participants
            v-if="timetableBlockPrice"
            :booking="booking"
            :timetable-block-price="timetableBlockPrice"
          />
        </div>
        <participants-header
          v-if="display"
          :booking="booking"
          :position="position"
          :variations="variations"
          :timetable-block-price="timetableBlockPrice"
          @on:abort="$emit('hidden')"
          @on:delete-succeed="$emit('hidden-and-update')"
          @on:participants:update="$emit('hidden-and-update')"
        />
      </div>
      <div slot="footer">
        <modal-footer
          :is-busy="isBusy"
          :booking="booking"
          :position="position"
          :inner-width="innerWidth"
          :timetable-block-price="timetableBlockPrice"
          @hidden="$emit('hidden')"
          @on:booking:update="updateBookingName"
        />
      </div>
    </template>
    <template
      v-else
    >
      <div class="img-container ml-5" slot="body">
        <img
          class="pointer loader-booking d-flex align-items-center align-content-center justify-content-center"
          :src="require('@/assets/planning-loader.svg')"
        />
      </div>
    </template>
  </d-side-bar>
</template>
<script>
import {enableBodyScrollY} from "@/utils/style";
import {LESSON_TYPE} from "@/classes/doinsport/Price";
import {_get} from "@api/doinsport/services/httpService";
import {toTimeFormat, toTimezoneCustomFormat} from "@/utils/timezone";
import {DATE_DASH_TIME, DAY_SLASH_MONTH_SLASH_YEAR} from "@/utils/date";
import {getBooking, updateBooking} from "@api/doinsport/services/bookings/booking.api";
import {getPriceCategories, getPriceVariations} from "@api/doinsport/services/timetable/blocks/price/price.api";

export default {
  components: {
    Participants: () => import('./Participants'),
    ModalFooter: () => import("@views/planning/schedule/playgrounds/bookings/details/Footer"),
    ParticipantsHeader: () => import("@views/planning/schedule/playgrounds/bookings/details/ParticipantsHeader")
  },
  data: () => ({
    isBusy: false,
    booking: null,
    display: false,
    variations: [],
    timetableBlockPrice: null,
  }),
  props: {
    bookingId: {
      type: String,
      default: null,
    },
    display: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left'
    },
    top: {
      type: String,
      default: '61px'
    },
    custom: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    bookingId: function () {
      if (this.bookingId) {
        this.loadBooking();
      }
    },
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    },
    BookingPlayground() {
      let name = '';
      const playgrounds = this.booking.playgrounds;

      for (const i in playgrounds) {
        name += parseInt(i) !== playgrounds.length - 1 ? ', ' + playgrounds[i].name : playgrounds[i].name;
      }

      return name;
    },
    dateTime() {
      const endAtTz = toTimeFormat(this.booking.endAt);
      const startAtTz = toTimezoneCustomFormat(this.booking.startAt, DATE_DASH_TIME);

      return `${startAtTz} - ${endAtTz}`;
    },
    dateBooking() {
      return toTimezoneCustomFormat(this.booking.startAt, DAY_SLASH_MONTH_SLASH_YEAR);
    },
    dateStartEnd() {
      return `${toTimeFormat(this.booking.startAt)} - ${toTimeFormat(this.booking.endAt)}`;
    }
  },
  methods: {
    loadBooking() {
      if (this.bookingId) {
        getBooking(this.bookingId)
          .then((response) => {
            this.booking = response.data;
          })
          .finally(() => {
            this.loadTimetableBlockPrice();
          })
        ;
      }
    },
    onChange() {
      this.$emit('change');
    },
    beforeHide() {
      if (!this.custom) {
        if (this.booking && !this.booking.nameManuallyUpdated) {
          updateBooking(this.booking.id, {name: this.booking.name})
            .then((response) => {
              this.$bus.$emit('on:booking-update', response.data);
              this.$emit('hidden', response.data);
            })
            .finally(() => {
              this.booking = null;
            })
          ;
        } else {
          this.$bus.$emit('on:booking-update', this.booking);
          this.$emit('hidden', this.booking);
          this.booking = null;
        }
      } else {
        enableBodyScrollY();
        this.$emit('hidden');
        this.$bus.$emit('on:multiple-booking-model:update', this.booking);
      }
    },
    updateBookingName() {
      if (!this.isBusy) {
        this.isBusy = true;
        this.getBookingUpdateRequest()
          .finally(() => {
            enableBodyScrollY();
            this.isBusy = false;
            this.$router.push({name: 'booking_update', params: {id: this.booking.id}});
          })
        ;
      }
    },
    getBookingUpdateRequest() {
      const updateCondition = this.booking && this.booking.activityType !== LESSON_TYPE && false === this.booking.nameManuallyUpdated;
      return updateCondition ? updateBooking(this.booking.id, {name: this.booking.name}) : Promise.resolve();
    },
    loadTimetableBlockPrice() {
      _get(this.booking.timetableBlockPrice['@id'])
        .then((response) => {
          this.loadBlockPriceVariations(response.data);
        })
      ;
    },
    loadBlockPriceCategories(blockPrice) {
      getPriceCategories(blockPrice.id, 100)
        .then((response) => {
          this.$store.commit('multipleBooking/setPriceCategories', response.data['hydra:member']);
        })
        .finally(() => {
          this.timetableBlockPrice = blockPrice;
        })
      ;
    },
    loadBlockPriceVariations(blockPrice) {
      getPriceVariations(blockPrice.id, 100)
        .then((response) => {
          this.variations = response.data['hydra:member'];
        })
        .finally(() => {
          this.loadBlockPriceCategories(blockPrice);
        })
      ;
    }
  },
  created() {
    this.loadBooking();
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/bookings/participants/_index.scss";

.divider {
  border-right: 2px solid #c3bfbf;
  height: 100%;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #0000001a;
}

.container-body-scroll {
  max-height: 78%;
  padding-bottom: 70px;
  overflow-x: hidden;
  overflow-y: scroll
}
</style>
